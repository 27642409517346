/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                this.cookieConfirm();
            },
            cookieConfirm: function () {
                $('.btn[data-action=close-confirm]').on('click', function (e) {
                    $('.cookie-confirmation').remove();
                }); 
            },
            carousel: function () {
                $('.carousel').carousel({
                    interval: false
                });
            },
            animatedNav: function () {
                function animateNav(to) {
                    $('html, body').animate({
                        scrollTop: to
                    }, 600);
                }

                $('.menu-item a').on('click', function (e) {
                    if ($(this).attr('target') === undefined && $(this).attr('href').indexOf('http:') < 0) {
                        e.preventDefault();
                        var target = $(this).attr('href');
                        var targetPos = $(target).offset().top;
                        animateNav(targetPos);
                    }
                });

                $(document).on('click', function (e) {
                    var target = $(e.target);
                    var parentClass = target.parent().attr('class');
                    if (parentClass !== undefined) {
                        if (parentClass.indexOf('menu-item') < 0) {
                            $('#nav-primary').collapse('hide');
                        }
                    } else {
                        $('#nav-primary').collapse('hide');
                    }
                });
            },
            galleryItems: function () {
                $('.gallery-item').on('click', function (e) {
                    e.preventDefault();
                    var parent = $(this).closest('.gallery');
                    var parentID = parent.attr('id');

                    var active_url = $(this).attr('href');
                    var img_urls = [];
                    $('.gallery-item').each(function (i) {
                        if ($(this).closest('.gallery').attr('id') === parentID) {
                            img_urls[i] = $(this).attr('href');
                        }
                    });

                    $.ajax({
                        url: gallery.ajax_url,
                        type: 'post',
                        data: {
                            action: 'getGalleryImage',
                            img_urls: img_urls,
                            active_url: active_url
                        },
                        beforeSend: function () {
                            $('#gallery-modal').modal('show');
                            var imgClone = $('.brand img').clone();
                            imgClone.addClass('brand-logo');
                            $('#gallery-modal .modal-content .current-image').html(imgClone);
                        },
                        success: function (response) {
                            $('#gallery-modal .modal-content .current-image').html(response.split('%%')[1]);
                        }
                    });
                });
            },
            modalPage: function () {
                $('a[data-target="#footer-modal"]').on('click', function (e) {
                    e.preventDefault();
                    var pageName = $(this).attr('href');
                    var action = $(this).data('action');
                    
                    $.ajax({
                        url: gallery.ajax_url,
                        type: 'post',
                        data: {
                            action: action,
                            pageName: pageName
                        },
                        beforeSend: function () {
                            var imgClone = $('.brand img').clone();
                            imgClone.addClass('brand-logo');
                            $('#footer-modal .modal-content .modal-header .modal-title').html('Betöltés...');
                            $('#footer-modal .modal-content .modal-body').html(imgClone);
                        },
                        success: function (response) {
                            var toDisplay = JSON.parse(response.split('%%')[1]);
                            $('#footer-modal .modal-content .modal-header .modal-title').html(toDisplay.gomb_felirat);
                            $('#footer-modal .modal-content .modal-body').html(toDisplay.tartalom);
                        }
                    });
                });
            },
            finalize: function () {
                this.modalPage();
                this.galleryItems();
                this.animatedNav();
                this.carousel();
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
